/*
 * Output font size in px/rem
 */

@use "sass:math";

@mixin font-size($px) {
	font-size: $px + px;
	font-size: #{math.div($px, 10)}rem;
}

/**
 * Clearfix
 */

@mixin clearfix {
	&:before,
	&:after {
		content: " ";
		display: table;
		flex-basis: 0;
		width: 0;
		height: 0;
		order: 1;
	}
	&:after {
		clear: both;
	}
}

%clearfix {
	&:before,
	&:after {
		content: " ";
		display: table;
		flex-basis: 0;
		width: 0;
		height: 0;
		order: 1;
	}
	&:after {
		clear: both;
	}
}

/* = Responsive Layouts */

$fix-mqs: false !default;
// MIN
@mixin respond-min($width) {
	// If we're outputting for a fixed media query set...
	@if $fix-mqs {
		// ...and if we should apply these rules...
		@if $fix-mqs>=$width {
			// ...output the content the user gave us.
			@content;
		}
	} @else {
		// Otherwise, output it using a regular media query
		@media only screen and (min-width: $width) {
			@content;
		}
	}
}

@mixin respond-max($width) {
	// If we're outputting for a fixed media query set...
	@if $fix-mqs {
		// ...and if we should apply these rules...
		@if $fix-mqs<=$width {
			// ...output the content the user gave us.
			@content;
		}
	} @else {
		// Otherwise, output it using a regular media query
		@media only screen and (max-width: $width) {
			@content;
		}
	}
}

@mixin mobileMedium() {
	@include respond-min($xxs) {
		@content;
	}
}

@mixin mobileLarge() {
	@include respond-min($xs) {
		@content;
	}
}

@mixin tablet() {
	@include respond-min($sm) {
		@content;
	}
}

@mixin tabletLarge() {
	@include respond-min($md) {
		@content;
	}
}

@mixin desktop() {
	@include respond-min($lg) {
		@content;
	}
}

@mixin desktopLarge() {
	@include respond-min($xl) {
		@content;
	}
}

/* = GRID SYSTEM */

// LAYOUT
// Use: (Breakpoint, Top number in fraction, bottom number in fraction)
@mixin layout($bp, $top: 1, $bottom: 1) {
	@if $bp>0 {
		@include respond-min($bp) {
			width: calc((#{$top} / #{$bottom}) * 100#{"%"} - #{$gutter});
		}
	} @else {
		width: calc((#{$top} / #{$bottom}) * 100#{"%"} - #{$gutter});
	}
}

// PUSH PULL
// Use: (Breakpoint, Top number in fraction, bottom number in fraction)
@mixin pushpull($bp, $top: 1, $bottom: 1, $direction: "push") {
	@if $direction== "push" {
		$direction: "left";
	} @else {
		$direction: "right";
	}
	@if $bp>0 {
		@include respond-min($bp) {
			margin-#{$direction}: calc(
				(#{$top} / #{$bottom}) * 100#{"%"} + #{$gutter * 0.5}
			);
		}
	} @else {
		margin-#{$direction}: calc((#{$top} / #{$bottom}) * 100#{"%"} + #{$gutter});
	}
}

// REMOVE
// Use: (Breakpoint, Top number in fraction, bottom number in fraction)
@mixin remove($bp, $top: 1, $bottom: 1, $direction: "right") {
	@if $direction== "left" {
		$direction: "left";
	} @else {
		$direction: "right";
	}
	@if $bp>0 {
		@include respond-min($bp) {
			margin-#{$direction}: calc(
				(#{$top} / #{$bottom}) * -100#{"%"} + #{$gutter * 0.5}
			);
		}
	} @else {
		margin-#{$direction}: calc(
			(#{$top} / #{$bottom}) * -100#{"%"} + #{$gutter}
		);
	}
}
