@import "@/utils/mixins";
@import "@/utils/variables";

.sidebar {
	background-color: $deep-green;
	padding: 0px 15px 10px 10px;
	position: fixed;
	left: 0;
	height: 60px;
	width: 180px;
	display: flex;
	flex-direction: column;
	z-index: 10;
	align-items: flex-start;
	color: $white;

	@include tablet() {
		height: 100%;
		padding: 0px 30px 10px 10px;
	}

	&.mobileOpen {
		height: 100%;
		.nav {
			display: block;
		}
	}

	&:after {
		content: "";
		position: absolute;
		top: 100%;
		left: calc(100% + -15px);
		height: 25px;
		width: 100vh;
		background-image: url(/images/header-bottom.svg);
		background-repeat: repeat;
		background-size: 20px 26px;
		transform: rotate(-90deg);
		transform-origin: top left;
		z-index: 2;
	}

	&.isClosed {
		width: 65px;
		padding-right: 10px;

		.logo {
			height: 30px;
			max-width: 40px;
			margin-left: 0;
			margin-top: 15px;
			margin-bottom: 20px;
		}

		.catTitle {
			display: none;
		}

		ul li .navLink {
			text-indent: -9999px;
			width: 34px;

			svg {
				width: 24px;
				height: 24px;
				margin-right: 0;
			}
		}
	}
}

.logoHolder {
	display: flex;
	width: 100%;
	height: 60px;
	padding: 0;
	margin: 0;
}

.burger {
	svg {
		width: 40px;
		height: 40px;
		max-width: 45px;
		margin-right: 10px;
		position: relative;
		top: 10px;
		path {
			fill: $color-lightest;
		}
	}
	@include tablet() {
		display: none;
	}
}

.logo {
	text-indent: -9999px;
	overflow: hidden;
	width: 100%;
	max-width: 90px;
	margin: 5px 10px 0 10px;
	height: 50px;
	background-image: url(/images/logo.png);
	background-repeat: no-repeat;
	background-position: center center;
	background-size: 100% auto;
	position: relative;

	@include tablet() {
		margin-top: 10px;
	}
}

.nav {
	margin-top: 15px;
	width: 100%;
	font-size: 1.4rem;
	display: none;
	ul {
		list-style: none;
		margin: 0;
		padding: 0;
		width: 100%;
	}

	li {
		margin: 0;
		padding: 0;
		width: 100%;
	}

	@include tablet() {
		display: block;
	}
}

.navLink {
	color: $white;
	padding: 6px 5px;
	width: 100%;
	border-radius: 5px;
	display: flex;
	align-items: center;
	font-size: 1.4rem;

	&:hover {
		color: $white;
		text-decoration: none;
		background-color: rgba(255, 255, 255, 0.1);
	}

	svg {
		top: -1px;
		position: relative;
		margin-right: 8px;
		width: 20px;
		height: 20px;
		color: $white;

		path {
			fill: currentColor;
		}
	}
}

.catTitle {
	margin-left: 5px;
	margin-top: 10px;
	opacity: 0.4;
	font-size: 1.4rem;
	font-weight: $bold-base;
}

.sideBarClose {
	cursor: pointer;
	position: absolute;
	bottom: 10px;
	left: 10px;
	color: $white;
	padding: 5px;
	border-radius: 5px;
	height: 34px;
	width: 34px;
	display: none;

	@include tablet() {
		display: block;
	}

	&:hover {
		color: $white;
		text-decoration: none;
		background-color: rgba(255, 255, 255, 0.1);
	}

	svg {
		width: 24px;
		height: 24px;
		position: relative;
		path {
			fill: currentColor;
		}
	}
}
