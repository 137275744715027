@import "@/utils/mixins";
@import "@/utils/variables";

.header {
	position: relative;
	top: 0;
	left: 0;
	width: 100%;
	display: flex;
	flex-direction: row;
	height: 60px;
	z-index: 9;
	border-bottom: 1px solid $light-grey;
	padding: 10px 20px 10px 210px;
	align-items: center;
	justify-content: flex-end;
	background-color: $off-white;

	@include tablet() {
		justify-content: space-between;
	}

	&.headerBig {
		padding-left: 90px;
	}

	h2 {
		margin: 0;
		font-size: 2.4rem;
		display: none;

		@include tablet() {
			display: block;
		}
	}
}

.nav {
	ul {
		display: flex;
		align-items: center;
		gap: 20px;
		list-style: none;
		margin: 0;
	}

	.todaysSales {
		font-size: 1.6rem;
		margin-bottom: 0;
		font-weight: $bold-base;
		display: none;

		@include tablet() {
			display: block;
		}
	}

	ul li.sellBtn {
		margin-bottom: 0;
		a {
			margin-bottom: 0;
		}
	}

	ul li {
		list-style: none;
		display: block;

		&.withDropdown {
			color: $deep-green;
			position: relative;
			margin: 0;

			&.isOpen {
				svg {
					transform: rotate(180deg);
				}
				ul {
					display: inline-block;
				}
			}

			span span {
				top: 0;
			}

			svg {
				display: none;
				cursor: pointer;
				width: 30px;
				height: 30px;
				vertical-align: middle;
				top: -1px;
				position: relative;
				transition: all 0.4s;
				@include respond-min($md) {
					display: inline-block;
				}
				path {
					fill: $deep-green;
				}
			}
		}
	}
	ul.dropdown {
		display: none;
		position: absolute;
		width: 150px;
		background-color: $color-darkest;
		border-radius: 10px;
		right: 0;
		padding: 10px 0;
		top: calc(100% + 20px);

		li {
			display: block;
			padding: 0;
			margin: 0;

			a,
			button {
				color: $white;
				width: 100%;
				display: block;
				text-align: right;
				padding: 8px 20px;
			}
		}
	}
}
