@import "@/utils/mixins";
@import "@/utils/variables";

.h1,
.h2 {
	font-weight: $bold-heading;
	font-family: $headline-font-family;
	margin-bottom: 20px;
	width: 100%;
	line-height: 1.1;
	@include respond-min($sm) {
		margin-bottom: 30px;
	}
	em {
		font-style: normal;
	}
}

.h3,
.h4 {
	font-weight: $bold-base;
	font-family:$base-font-family;
	margin-bottom: 20px;
	width: 100%;
	line-height: 1.2;
	@include respond-min($sm) {
		margin-bottom: 20px;
	}
	em {
		font-style: normal;
	}
}

.h1 {
	@include font-size(26);
	@include respond-min($sm) {
		@include font-size(30);
	}
	@include respond-min($md) {
		@include font-size(32);
	}
}

.h2 {
	@include font-size(20);
	@include respond-min($sm) {
		@include font-size(24);
	}
	@include respond-min($md) {
		@include font-size(24);
	}
	@include respond-min($lg) {
		@include font-size(24);
	}
}

.h3 {
	@include font-size(20);
	@include respond-min($lg) {
		@include font-size(24);
	}
}

.h4 {
	@include font-size(24);
	line-height: 0.9;
	color: $color-darkest;
	@include respond-min($sm) {
		@include font-size(24);
	}
	@include respond-min($md) {
		@include font-size(30);
	}
}

.h5 {
	@include font-size(18);
	margin-bottom: 10px;
}

.h6 {
	@include font-size(18);
	margin-bottom: 10px;
	@include respond-min($sm) {
		@include font-size(16);
	}
}