@import "@/utils/mixins";
@import "@/utils/variables";

.profilePic {
	width: 40px;
	height: 40px;
	border-radius: 100%;
	background-color: $color-light;
	display: inline-block;
	grid-column: span 1;
	text-align: center;
	line-height: 40px;
	font-weight: $bold-base;
	@include font-size(16);
	overflow: hidden;
	position: relative;
	color: $color-darkest;
	vertical-align: middle;
	top: -2px;
	cursor: help;

	&.size-large {
		width: 50px;
		height: 50px;
		line-height: 50px;
		@include font-size(20);

		@include tablet() {
			width: 80px;
			height: 80px;
			line-height: 80px;
			@include font-size(30);
		}
	}

	&.size-small {
		width: 24px;
		height: 24px;
		line-height: 24px;
		@include font-size(12);
	}

	img {
		object-fit: cover;
	}
}
