@import "@/utils/mixins";
@import "@/utils/variables";

.divider {
  position: relative;
  text-align: center;
  display: block;
	@include font-size(20);
  width: 80%;
  margin: 0px auto 15px;
	z-index:1;

	@include respond-min($sm){
		margin: 0px auto 20px;
	}
  &:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 1px;
    top: 16px;
    left: 0;
    background-color: $color-dark;
    z-index: -1;
  }
  
}

.dividerText {
	color: $color-darkest;
	&:before {
		background-color: $color-darkest;
	}
	&:after {
    content: "";
    width: 40px;
    position: absolute;
    z-index: -1;
    left: 50%;
    margin-left: -20px;
    top: 0;
    height: 100%;
    background-color: $color-light-brand;
  }
}
